<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Good Issue Return">
            <vs-tabs :color="colorx">
                <vs-tab @click="colorx = 'success'" label="Open">
                    <div class="con-tab-ejemplo">
                        <complete-retur></complete-retur>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Reversed">
                    <div class="con-tab-ejemplo">
                        <reversed-retur></reversed-retur>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import completeRetur from "./complete/datatable.vue";
import reversedRetur from "./reversed/datatable.vue";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        'complete-retur': completeRetur,
        'reversed-retur': reversedRetur,
        Datepicker, 
    },
    data: () => ({
        colorx: "success",
    }),
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>